@import "../../scss/index.scss";

.login-form {
  text-align: left;

  .ant-form-item {
    &:last-of-type {
      margin: 0;
    }
  }

  &__input {
    .ant-input-prefix {
      i {
        font-size: 0em+22px / $default-font-size;
      }
    }

    input {
      font-size: 0em+20px / $default-font-size;
      padding: 20px 20px 20px 40px !important;
      &:focus {
        border-color: $primary-color;
        box-shadow: none;
      }
    }
  }

  &__button {
    width: 100%;
    font-size: 0em+22px / $default-font-size;
    background-color: $primary-color;
    color: $font-light;
    height: 42px;
    margin-top: 20px;

    &:hover {
      background-color: $primary-color-hover;
      color: $font-light;
    }
    &:focus,
    &:active {
      background-color: $primary-color;
      color: $font-light;
      border: 0;
    }
  }
}
