$primary-color: #0098d3;
$primary-color-hover: #0280b3;

$menu-color: #252527;

$font-light: #fff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #000;
$font-dark-light: #252527;
$font-dark: #000;

$border-light: #fff;
$border-grey-light: #e8e8e8;
$border-grey: #808080;
$border-grey-dark: #595959;
$border-dark-light: #252527;
$border-dark: #000;

//Social color
$youtube: #cd201f;
$twitter: #1da1f2;
$facebook: #3b5998;
$linkedin: #0077b5;

$background-light: #fff;
$background-grey-light: #e8e8e8;
$background-grey: #808080;
$background-grey-dark: #595959;
$background-dark-light: #252527;
$background-dark: #000;
$background-error: #ff00001f;
$background-success: #84b84c1f;

$success: #84b84c;
$error: #f00;
$warning: orange;
