@import '../scss/index.scss';

.layout-admin {
	transition: margin-left 0.2s;

	&__header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		padding: 0;
		height: 65px;
		z-index: 10;
	}

	&__content {
		min-height: calc(100vh - 65px);
		padding: 90px 25px 25px 25px;
	}

	&__footer {
		padding: 20px;
		padding-bottom: 30px;
		text-align: right;
		font-size: 10px;
	}
}
