@import '../../../../scss/index.scss';

.list-users {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		&__switch {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			> button {
				margin-right: 20px;
			}
		}
	}

	.users-active {
		background-color: $background-light;
		padding: 10px 20px;

		.ant-list-item-meta {
			display: flex;
			align-items: center;
		}
	}
}
