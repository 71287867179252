@import '../../../../scss/index.scss';

.certificate-list {
	background-color: $background-light;
	padding: 10px 20px;

	.ant-avatar {
		width: 50px;
		height: 50px;
	}

	.ant-list-item-meta {
		display: flex;
		align-items: center;
	}

	.social-links {
		padding: 15px;
		border-left: 1px solid #e8e8e8;

		h3 {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			font-weight: normal;
		}

		&__detail {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;

			a {
				display: flex;
				margin-right: 10px;
				padding: 5px 8px;
				border-radius: 5px;

				&.youtube {
					background-color: $youtube;
				}

				&.facebook {
					background-color: $facebook;
				}

				&.linkedin {
					background-color: $linkedin;
				}

				&.twitter {
					background-color: $twitter;
				}

				svg {
					width: 15px;
					height: 20px;
				}
			}
		}
	}
}
